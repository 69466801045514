import { addClass } from "./common/add-class";
import { removeClass } from "./common/remove-class";

class ProductTable {
    constructor(
        public tabList: HTMLElement[],
        public currentIndex: number,
        public prodchartCellClassName: string,
    ) {
        this.tabList = tabList;
        this.currentIndex = currentIndex;
        this.prodchartCellClassName = prodchartCellClassName;
    }

    public init() {
        const col = Array.from(
            document.querySelectorAll<HTMLElement>(
                `[data-column="${this.currentIndex}"]`,
            ),
        );
        addClass(col, this.prodchartCellClassName);
        this.initEvent();
    }

    private initEvent() {
        this.tabList.forEach((tab) => {
            tab.addEventListener("click", (evt: MouseEvent) => {
                const target: HTMLElement = evt.currentTarget as HTMLElement;
                const index = Array.from(this.tabList).indexOf(target);
                this.toggleColumns(index);
            });
        });
    }

    private toggleColumns(index: number) {
        const currentCol = Array.from(
            document.querySelectorAll<HTMLElement>(
                `[data-column="${this.currentIndex}"]`,
            ),
        );
        const col = Array.from(
            document.querySelectorAll<HTMLElement>(`[data-column="${index}"]`),
        );

        removeClass(currentCol, this.prodchartCellClassName);
        addClass(col, this.prodchartCellClassName);

        this.toggleTabs(index);
        this.currentIndex = index;
    }

    private toggleTabs(index: number) {
        this.tabList[this.currentIndex].setAttribute("aria-selected", "false");
        this.tabList[index].setAttribute("aria-selected", "true");
    }
}

const productTable = new ProductTable(
    Array.from(document.querySelectorAll<HTMLElement>(".prodchart__tab")),
    0,
    "prodchart__cell--on",
);
productTable.init();

document.querySelectorAll<HTMLElement>(".prodchart-trigger").forEach((elem) => {
    elem.addEventListener("click", () => {
        const tid = elem.dataset["id-trigger"];
        const cell = document.querySelector(
            `.prodchart__cell[data-extra-id="${tid}"]`,
        );
        const para = cell?.querySelector("p");
        if (para) {
            para.style.display =
                para.style.display === "block" ? "none" : "block";
        }
        cell?.classList.toggle("prodchart__cell--closed");
        cell?.classList.toggle("prodchart__cell--expanded");
    });
});
