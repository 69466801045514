// Import polyfills and setup error reporting system
import "./src/_bootstrap";
import { Provider, getServerData, getTemplate } from "@reactivated";
import { initPage } from "@thelabnyc/thelabui/src/index";
import Modal from "react-modal";
import { trackPageView } from "tsi-common-react/src/utils/analytics";
import {
    onDOMContentLoaded,
    onPageVisible,
} from "tsi-common-react/src/utils/events";
import { registerChatConnector } from "tsi-common-react/src/apps/chat/index";
import { AdaChatConnector } from "tsi-common-react/src/apps/chat/connector-ada";
import { getPageSetting } from "tsi-common-react/src/utils/settings";
import { initWidgets } from "tsi-common-react/src/widgets";
import { strToBool } from "tsi-common-react/src/utils/format";

// Remove no-js class from HTML tag
const htmlElem = document.querySelector("html");
if (htmlElem) {
    htmlElem.classList.remove("no-js");
}

// Load CSS
import "./src/styles/main.scss";

// Set tsi-common-react configuration
import "./src/localization";

// This is the main entry point for new-style thelab-ui / reactivated
// components (which support SSR)
initPage({
    Provider,
    getServerData,
    getTemplate,
}).catch((e) => {
    console.error(e);
});

// Set the root app element for react-modal
if (document.querySelector("body > .main-content")) {
    Modal.setAppElement("body > .main-content");
} else if (document.querySelector("#root")) {
    Modal.setAppElement("#root");
}

// Configure live chat
const chatEnabled = strToBool(getPageSetting("chat-enabled", "false"));
if (chatEnabled) {
    const hideChat = strToBool(getPageSetting("chat-hide-widget", "true"));
    const chatConnector = new AdaChatConnector({
        settings: {
            handle: "tsi-sealy",
        },
    });
    registerChatConnector(chatConnector);
    chatConnector.init({
        hideChat: hideChat,
    });
}

// Configure analytics
onDOMContentLoaded.on(async () => {
    // If the page isn't currently visible, wait until it is to apply tracking
    await onPageVisible;
    trackPageView();
});

// Load Site Components
initWidgets();

import "./src/ui/main";
import "./src/ui/image-comparison";
import "./src/ui/helpers";
import "./src/ui/carousel";
import "./src/ui/product-table";
import "./src/ui/value-prop";
import "./src/ui/finance-faq";
import "./src/ui/lazyloading";
import "./src/ui/configurator-sticky";

import "./src/Checkout/index";
import "./src/Common/index";
import "./src/Orders/index";
import "./src/ProductDetail/index";
import "./src/Reviews/index";
import "./src/ProductGrid/index";
import "./src/RetailLocator/index";
import "./src/ProductCompare/index";
import "./src/TempurLoveGrid/index";
import "./src/UGCHomepage/index";
import "tsi-common-react/src/blocks/wistia-background-video";
import "tsi-common-react/src/blocks/columns-carousel-block";
