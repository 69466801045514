import React from "react";
import { IMenuCategoryLink, IDropdownMenuItem } from "../menu.interface";
import NavFooterComp from "./NavFooterComp";

interface IProps {
    flinks: IMenuCategoryLink[];
}

const NavFooter = (props: IProps) => {
    let navFooterEle: JSX.Element | null = null;
    props.flinks.forEach((menuItem: IMenuCategoryLink) => {
        if (menuItem.type !== "nav_category") {
            return;
        }
        if (menuItem.value.sub_nav.length > 0) {
            menuItem.value.sub_nav.forEach((val: IDropdownMenuItem) => {
                switch (val.type) {
                    case "nav_footer":
                        navFooterEle = <NavFooterComp navFooterEl={val} />;
                        break;
                    default:
                        break;
                }
            });
        }
    });

    return navFooterEle;
};

export default NavFooter;
