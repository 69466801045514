import React from "react";
import SVG from "react-inlinesvg";
import {
    ILocation,
    IRetailStoreWithDistance,
} from "../../../models/location.interfaces";
import { formatPhoneNumber } from "../../../utils/format";
import { IWebPageURL } from "../../../models/nominals";
import { config } from "../../../config";
import { Image } from "../../../common/Image";
import { formatDistanceWithSuffix } from "../helpers";
import styles from "./RetailCards.module.scss";
import iconPin from "../../../../img/icons/pin.svg";
import iconPhone from "../../../../img/icons/phone.svg";
import iconTurn from "../../../../img/icons/turn.svg";

interface ICardProps {
    store: IRetailStoreWithDistance;
}

interface IBlockProps {
    stores: IRetailStoreWithDistance[];
    location: ILocation | null;
    retailLocatorLink: IWebPageURL;
}

const RetailCard = (props: ICardProps) => {
    // encode destination address for Directions link
    const destinationAddress = encodeURI(
        props.store.address +
            " " +
            props.store.address2 +
            " " +
            props.store.city +
            "," +
            props.store.state +
            " " +
            props.store.postal,
    );
    return (
        <div className={styles.card}>
            <header>
                <Image
                    alt="Gallery Image 1"
                    src={`https://maps.googleapis.com/maps/api/staticmap?center=${
                        props.store.lat
                    },${props.store.lng}
                         &zoom=15
                         &size=400x300
                         &markers=${props.store.lat},${props.store.lng}
                         &key=${config.get("GOOGLE_MAPS_API")}`}
                />
                <p>
                    <em>
                        <strong>
                            {formatDistanceWithSuffix(props.store.distance)}
                        </strong>
                    </em>
                </p>
            </header>
            <SVG className="" src={iconPin} />
            <h4>{props.store.name}</h4>
            <p>
                {props.store.address}
                <br />
                {props.store.address2}
                {props.store.address2 && <br />}
                {props.store.city}, {props.store.state} {props.store.postal}
            </p>
            {props.store.phone && <SVG className="" src={iconPhone} />}
            {props.store.phone && (
                <a href={`tel:${props.store.phone}`}>
                    {formatPhoneNumber(props.store.phone)}
                </a>
            )}
            <nav>
                <a
                    href={`https://www.google.com/maps/dir/?api=1&destination=${destinationAddress}`}
                    target="_blank"
                    className="button caption"
                    rel="noreferrer"
                >
                    <SVG className="" src={iconTurn} />
                    <span>Directions</span>
                </a>
            </nav>
        </div>
    );
};

export const RetailCardsBlock = (props: IBlockProps) => {
    return (
        <div className={styles.retailCardsContainer}>
            <div className={styles.retailCards}>
                {props.location && (
                    <>
                        <h2>Closest retailers near</h2>
                        <a href={`${props.retailLocatorLink}`}>
                            {props.location.formatted_address}
                        </a>
                    </>
                )}
                <div>
                    {props.stores.slice(0, 4).map((store) => (
                        <RetailCard
                            {...props}
                            key={`${store.id}`}
                            store={store}
                        />
                    ))}
                </div>
                <a
                    href={`${props.retailLocatorLink}`}
                    className="button --secondary"
                >
                    View all retailers
                </a>
            </div>
        </div>
    );
};
