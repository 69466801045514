document.querySelectorAll(".accordion__trigger").forEach((elem) => {
    elem.addEventListener("click", (event) => {
        const panel =
            elem.parentElement?.querySelector<HTMLElement>(".accordion__panel");
        if (panel) {
            panel.style.display =
                panel.style.display === "block" ? "none" : "block";
        }
        elem.parentElement?.classList.toggle("is-expanded");
        event.preventDefault();
    });
});
