import React from "react";
import classNames from "classnames";
import { t } from "ttag";
import { FormCheckbox } from "../forms/FormCheckbox";
import { Modal, ModalStyles } from "./Modal";
import { IWarrantyForm } from "../models/warranty-form";
import styles from "./WarrantyForm.module.scss";

interface IProps {
    cms: IWarrantyForm;
    propertyName: string;
    claimURL: string;
}

interface IState {
    selectedChannel: string | null;
    modalOpen: boolean;
    showAsterisk: boolean;
    submitted: boolean;
}

export class WarrantyForm extends React.Component<IProps, IState> {
    public state: IState = {
        selectedChannel: null,
        modalOpen: false,
        showAsterisk: false,
        submitted: false,
    };

    private readonly handleChange = (
        event: React.FormEvent<HTMLInputElement>,
    ) => {
        this.setState({ selectedChannel: event.currentTarget.value });
    };

    private readonly handleSuccess = () => {
        window.location.href = this.props.claimURL;
    };
    private buildSuccessMsg() {
        const successClasses = classNames({
            [styles.resultModal]: true,
        });
        return (
            <div className={successClasses}>
                <h1 className={styles.heading}>
                    Online claim submission is available to you
                </h1>
                <p>
                    Please click below to continue filing your warranty claim
                    online with the necessary information.
                </p>
                <button
                    type="button"
                    className="button"
                    onClick={this.handleSuccess}
                >
                    {t`File Warranty Claim`}
                </button>
            </div>
        );
    }
    private buildErrorMsg() {
        const errorClasses = classNames({
            [styles.resultModal]: true,
            [styles.errorModal]: true,
        });
        const error = this.props.cms.error_message;
        return (
            <div className={errorClasses}>
                <h1 className={styles.heading}>{error.title}</h1>
                <p>{error.body}</p>
                <button type="button" className="button" onClick={this.onClose}>
                    {error.button}
                </button>
            </div>
        );
    }

    private buildOption(channel: string) {
        const isChecked = this.state.selectedChannel === channel;
        const classes = classNames({
            [styles.option]: true,
            [styles.checked]: isChecked,
        });
        return (
            <li key={channel} className={classes}>
                <FormCheckbox
                    required={true}
                    name={channel}
                    id={channel}
                    label={channel}
                    checked={this.state.selectedChannel === channel}
                    value={channel}
                    onChange={this.handleChange}
                    readOnly
                />
            </li>
        );
    }
    private readonly buildModalContent = () => {
        const buttonClasses = classNames({
            button: true,
            [styles.uppercase]: true,
        });
        const channels = this.props.cms.purchase_channels || [];
        const content = (
            <fieldset className={styles.fieldset}>
                <legend>
                    <h1 className={styles.heading}>
                        Where did you purchase your {this.props.propertyName}{" "}
                        product?
                        {this.state.showAsterisk && (
                            <span className={styles.error}>*</span>
                        )}
                    </h1>
                </legend>
                <ul className={styles.form}>
                    {channels.map((channel: string) => {
                        return this.buildOption(channel);
                    })}
                </ul>

                <button
                    aria-label={t`Close`}
                    type="button"
                    className={buttonClasses}
                    onClick={this.submitForm}
                >
                    {t`Next`}
                </button>
            </fieldset>
        );
        return content;
    };

    private readonly submitForm = () => {
        if (!this.state.selectedChannel) {
            this.setState({ showAsterisk: true });
        } else {
            this.setState({ submitted: true });
        }
    };
    private readonly onClose = (
        event: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
    ) => {
        if (event) {
            event.preventDefault();
        }
        this.setState({
            modalOpen: false,
        });
    };
    componentDidMount() {
        const channels = this.props.cms.purchase_channels;

        document.documentElement.style.setProperty(
            `--warranty-form--channels-row-count`,
            Math.ceil(channels.length / 2).toString() || "0",
        );
        const buttons =
            document.querySelectorAll<HTMLAnchorElement>("a[class='button']");
        if (buttons) {
            const claimBtn = Array.from(buttons).find((el) => {
                return el.innerText.toLowerCase().includes("claim");
            });

            if (claimBtn) {
                claimBtn.addEventListener("click", () => {
                    this.setState({ modalOpen: true });
                });
            }
        }
    }
    componentWillUnmount() {
        this.setState({ modalOpen: false });
    }

    render() {
        const modalStyle: ModalStyles = {
            content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                padding: "30px 20px",
            },
        };
        const modalClasses = classNames({
            [styles.warrantyModal]: true,
            warrantyModal: true,
        });
        const modalProps = {
            isOpen: this.state.modalOpen,
            aria: { modal: true },
            contentLabel: t`Warranty Form`,
            className: modalClasses,
            style: modalStyle,
            role: "dialog",
            onRequestClose: this.onClose,
        };
        let content;
        if (this.state.submitted) {
            if (this.state.selectedChannel?.toLowerCase() === "other") {
                content = this.buildErrorMsg();
            } else if (this.state.selectedChannel !== null) {
                content = this.buildSuccessMsg();
            }
        } else {
            content = this.buildModalContent();
        }
        return <Modal {...modalProps}>{content}</Modal>;
    }
}
