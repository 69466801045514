import React from "react";
import { assertNever } from "../../../utils/never";
import {
    IPLCProductCategorySelector,
    IPLCOptionPanel,
    IPLCProductOptionSelector,
} from "../models.interfaces";
import { PLCMattressSizeOptionSelector } from "./PLCMattressSizeOptionSelector";
import { PLCMattressFeelOptionSelector } from "./PLCMattressFeelOptionSelector";
import { PLCBooleanOptionSelectorGroup } from "./PLCBooleanOptionSelectorGroup";
import { PLCOtherOptionSelector } from "./PLCOtherOptionSelector";

interface IProps {
    categorySelectors: IPLCProductCategorySelector[];
    optionPanels: IPLCOptionPanel[];
    selector: IPLCProductOptionSelector;
}

export const PLCProductOptionSelector = (props: IProps) => {
    const { categorySelectors, optionPanels, selector } = props;
    switch (selector.type) {
        case "mattress_size":
            return (
                <PLCMattressSizeOptionSelector
                    categorySelectors={categorySelectors}
                    optionPanels={optionPanels}
                    selector={selector}
                />
            );
        case "mattress_feel":
            return (
                <PLCMattressFeelOptionSelector
                    categorySelectors={categorySelectors}
                    optionPanels={optionPanels}
                    selector={selector}
                />
            );
        case "boolean_option_group":
            return (
                <PLCBooleanOptionSelectorGroup
                    categorySelectors={categorySelectors}
                    optionPanels={optionPanels}
                    group={selector}
                />
            );
        case "other":
            return (
                <PLCOtherOptionSelector
                    categorySelectors={categorySelectors}
                    optionPanels={optionPanels}
                    selector={selector}
                />
            );
    }
    throw assertNever(selector);
};
