const raf = window.requestAnimationFrame
    ? window.requestAnimationFrame
    : (fn: () => void) => {
          setTimeout(fn, 100);
      };

const drags = (
    dragElement: HTMLElement,
    resizeElement: HTMLElement,
    container: HTMLElement,
) => {
    let onMouseMove = (_moveEvent: MouseEvent) => {};

    const onMouseDown = (event: MouseEvent) => {
        event.preventDefault();

        dragElement.classList.add("draggable");
        resizeElement.classList.add("resizable");

        const dragWidth = dragElement.offsetWidth;
        const xPosition =
            dragElement.getBoundingClientRect().left +
            document.body.scrollLeft +
            dragWidth -
            event.pageX;
        const containerOffset =
            container.getBoundingClientRect().left + document.body.scrollLeft;
        const containerWidth = container.offsetWidth;
        const minLeft = containerOffset + 10;
        const maxLeft = containerOffset + containerWidth - dragWidth - 10;

        onMouseMove = (moveEvent: MouseEvent) => {
            console.log("onMouseMove");
            raf(() => {
                animateDraggedHandle(
                    moveEvent,
                    xPosition,
                    dragWidth,
                    minLeft,
                    maxLeft,
                    containerOffset,
                    containerWidth,
                    dragElement,
                    resizeElement,
                );
            });
        };
        document.body.addEventListener("mousemove", onMouseMove);
    };

    const onMouseUp = () => {
        dragElement.classList.remove("draggable");
        resizeElement.classList.remove("resizable");
        document.body.removeEventListener("mousemove", onMouseMove);
    };

    dragElement.addEventListener("mousedown", onMouseDown);
    dragElement.addEventListener("mouseup", onMouseUp);
    document.body.addEventListener("mouseup", onMouseUp);
};

const animateDraggedHandle = (
    event: MouseEvent,
    xPosition: number,
    dragWidth: number,
    minLeft: number,
    maxLeft: number,
    containerOffset: number,
    containerWidth: number,
    dragElement: HTMLElement,
    resizeElement: HTMLElement,
) => {
    let leftValue = event.pageX + xPosition - dragWidth;
    // constrain the draggable element to move inside his container
    if (leftValue < minLeft) {
        leftValue = minLeft;
    } else if (leftValue > maxLeft) {
        leftValue = maxLeft;
    }
    const widthValue = `${
        ((leftValue + dragWidth / 2 - containerOffset) * 100) / containerWidth
    }%`;
    dragElement.style.left = widthValue;
    resizeElement.style.width = widthValue;
};

const imageComparisonContainers = document.querySelectorAll<HTMLElement>(
    ".cd-image-container",
);
imageComparisonContainers.forEach((elem) => {
    elem.classList.add("is-visible");
    const dragElement = elem.querySelector<HTMLElement>(".cd-handle");
    const resizeElement = elem.querySelector<HTMLElement>(".cd-resize-img");
    if (dragElement && resizeElement) {
        drags(dragElement, resizeElement, elem);
    }
});
