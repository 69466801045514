import React from "react";
import { Provider } from "react-redux";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";
import {
    ProductCompareGridCMSData,
    ProductSelectionGridCMSData,
} from "tsi-common-react/src/models/product-compare";
import { Dispatchers } from "tsi-common-react/src/apps/product-compare/dispatchers";
import { Loaders } from "tsi-common-react/src/apps/product-compare/loaders";
import { ProductCompareTheme } from "tsi-common-react/src/constants";
import { check } from "tsi-common-react/src/models/utils";
import { urls } from "tsi-common-react/src/utils/urls";
import { store } from "../store";

// Add constants for loading API data
const dispatchers = new Dispatchers(store.dispatch);
const loaders = new Loaders(dispatchers);

// Render ProductCompareGridBlocks
dynamicPlaceComponent(
    '[data-place-react="product-compare-grid"]',
    async (elem) => {
        const parsedData = check(
            ProductCompareGridCMSData.decode(
                JSON.parse(elem.dataset.cms || ""),
            ),
        );
        const loading = parsedData.products.map((baseTile) =>
            loaders.loadTile(baseTile),
        );
        const tiles = await Promise.all(loading);
        const { ProductCompareGrid } = await import(
            "tsi-common-react/src/apps/product-compare/containers/ProductCompareGrid"
        );
        return (
            <Provider store={store}>
                <ProductCompareGrid
                    header={parsedData.header}
                    basketLink={urls.pageURL("basket-summary")}
                    financingLink={urls.pageURL("finance-link")}
                    tiles={tiles}
                    sizeAttributeOptionGroup={
                        parsedData.size_attribute_option_group
                    }
                    preselectedSize={parsedData.preselected_size}
                    theme={
                        parsedData.theme.theme || ProductCompareTheme.DEFAULT
                    }
                />
            </Provider>
        );
    },
);

// Render the product selection grid
dynamicPlaceComponent(
    '[data-place-react="product-selection-grid"]',
    async (elem) => {
        const parsedData = check(
            ProductSelectionGridCMSData.decode(
                JSON.parse(elem.dataset.cms || ""),
            ),
        );
        loaders.loadTiles(parsedData.products);
        const { ProductSelectionGrid } = await import(
            "tsi-common-react/src/apps/product-compare/containers/ProductSelectionGrid"
        );
        return (
            <Provider store={store}>
                <ProductSelectionGrid
                    header={parsedData.header}
                    financingLink={urls.pageURL("finance-link")}
                    productComparePageURL={
                        parsedData.product_compare_page?.url || null
                    }
                    sizeAttributeOptionGroup={
                        parsedData.size_attribute_option_group
                    }
                    preselectedSize={parsedData.preselected_size}
                    theme={
                        parsedData.theme.theme || ProductCompareTheme.DEFAULT
                    }
                    footer={parsedData.footer}
                    groupHeader={parsedData.group_columns_header?.[0]}
                    showSizeSelector={parsedData.show_size_selector}
                    showStartingAt={true}
                />
            </Provider>
        );
    },
);
