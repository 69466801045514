import { onReadyStateComplete } from "tsi-common-react/src/utils/events";

const topStickyConfiguratorContainer = document.querySelector<HTMLElement>(
    ".pdp-sticky-configurator",
);

export const hideTopStickyConfigurator = (
    hide: boolean,
    element: HTMLElement | null = null,
) => {
    if (!topStickyConfiguratorContainer) {
        return;
    }
    if (element) {
        if (hide) {
            element.classList.add("hide");
        } else {
            element.classList.remove("hide");
        }
    } else {
        if (hide) {
            topStickyConfiguratorContainer.classList.add("hide");
        } else {
            topStickyConfiguratorContainer.classList.remove("hide");
        }
    }
};

const triggerStickyRail = () => {
    let LARGE_SCREEN_TARGET = 0;
    const siteHeader = document.querySelector<HTMLElement>("header.header");
    const configurator =
        document.querySelector<HTMLElement>(".plc-configurator");

    Array.from([siteHeader], (elem) => {
        LARGE_SCREEN_TARGET += elem ? elem.offsetHeight : 0;
    });

    window.addEventListener("scroll", () => {
        const TARGET = LARGE_SCREEN_TARGET;
        if (configurator) {
            if (window.scrollY >= TARGET && TARGET === LARGE_SCREEN_TARGET) {
                configurator.classList.add("sticky");
            } else {
                configurator.classList.remove("sticky");
            }
        }
    });

    window.addEventListener("resize", () => {
        if (configurator) {
            configurator.classList.remove("sticky");
        }
    });
};

onReadyStateComplete.on(async () => {
    triggerStickyRail();
});
