import Flickity from "flickity";

// Init Flickity after completely loaded all content
window.addEventListener("load", () => {
    const carouselElement =
        document.querySelector<HTMLElement>(".carousel__cells");
    if (!carouselElement) {
        return;
    }

    const flickity = new Flickity(carouselElement, {
        prevNextButtons: true,
        pageDots: false,
    });
    const carouselTabElements =
        document.querySelectorAll<HTMLElement>(".carousel__tab");
    const carouselTabs = Array.from(carouselTabElements);
    const active = "active";

    flickity.on("select", () => {
        carouselTabs.forEach((elem: HTMLElement) => {
            if (elem.classList.contains(active)) {
                elem.classList.remove(active);
            }
        });

        carouselTabElements[flickity.selectedIndex].classList.add(active);

        // set aria-current="true" on the active carousel cell
        carouselTabs.forEach((elem: HTMLElement) => {
            if (elem.getAttribute("aria-current") === "true") {
                elem.setAttribute("aria-current", "false");
            }
        });

        carouselTabElements[flickity.selectedIndex].setAttribute(
            "aria-current",
            "true",
        );
    });

    carouselTabs.forEach((elem: HTMLElement) => {
        elem.addEventListener("click", (event) => {
            const index = Array.prototype.indexOf.call(
                carouselTabElements,
                event.currentTarget,
            );
            flickity.select(index);
        });
    });

    return flickity;
});
