import React from "react";
import { IImageURL, ISafeHTML, IWebPageURL } from "../../../models/nominals";
import { IProduct } from "../../../models/catalogue.interfaces";
import { IAPIPrice, IPrice } from "../../../models/prices.interfaces";
import {
    IPLCProductCategorySelector,
    IPLCOptionPanel,
    IUpsellModalComponentClass,
} from "../models.interfaces";
import { ConfiguratorTypes } from "../../../constants";
import { DiscountDisplayMode } from "../constants";
import { ConfiguratorPrice } from "../../../common/ConfiguratorPrice";
import { RichText } from "../../../common/RichText";
import { ConfiguratorPriceLoading } from "../elements/ConfiguratorPriceLoading";
import { RatingsSummary } from "../elements/RatingsSummary";
import { AddToBasketButton } from "../elements/AddToBasketButton";
import { PLCProductCategorySelector } from "../elements/PLCProductCategorySelector";
import { PLCOptionPanel } from "../elements/PLCOptionPanel";
import { PLCProductMatchModal } from "../elements/PLCProductMatchModal";
import { getViewportBreakpoint } from "../../../utils/detectMobile";
import { urls } from "../../../utils/urls";

// Load styles
import "./PLCConfigurator.scss";
import { ConfiguratorDeliveryDisclaimer } from "../elements/ConfiguratorDeliveryDisclaimer";

interface IProps {
    preheader?: string;
    title: string;
    subhead?: string;
    description?: string;
    footer: ISafeHTML;
    categorySelectors: IPLCProductCategorySelector[];
    optionPanels: IPLCOptionPanel[];
    upgradedVariant: IProduct | null;
    price: IAPIPrice | null;
    selectedAddonPrice: IPrice | null;
    starRatingURL: IWebPageURL;
    basketLink: IWebPageURL;
    configureGiftsLink: IWebPageURL;
    showShippingLead?: boolean;
    shippingDescription: string | null;
    rootProduct: IProduct | null;
    phoneNumber?: string;
    getDeliveryCopy?: (
        rootProduct: IProduct,
        isPreorder: boolean,
    ) => string | null;
    getDeliveryIcon?: () => IImageURL | null;
    // Optional: Up Sell Modal functionality
    showUpsellModal?: (product: IProduct | null) => boolean;
    getUpsellModalComponentClass?: (
        product: IProduct,
    ) => IUpsellModalComponentClass | null;
}

interface IState {
    configuratorBottomPos: number;
}

const BEM_NAME = "plc-configurator";

export class PLCConfigurator extends React.PureComponent<IProps, IState> {
    public state: IState = {
        configuratorBottomPos: 0,
    };

    private configuratorElem: HTMLElement | undefined | null;

    private buildPrice() {
        if (!this.props.price || !this.props.upgradedVariant) {
            return (
                <ConfiguratorPriceLoading
                    configuratorType={ConfiguratorTypes.PLC}
                />
            );
        }
        return (
            <ConfiguratorPrice
                price={this.props.price}
                variant={this.props.upgradedVariant}
                selectedAddonPrice={this.props.selectedAddonPrice}
                configuratorType={ConfiguratorTypes.PLC}
                financingLink={urls.pageURL("finance-link")}
                strikeThroughMSRP={true}
                discountDisplayMode={DiscountDisplayMode.DISABLED}
                actualPriceStyle={""}
            />
        );
    }

    private buildHeader() {
        const text = (copy: string | undefined) => {
            if (!copy) {
                return null;
            }
            return <RichText html={copy} />;
        };
        return (
            <div className={`${BEM_NAME}__header`}>
                {text(this.props.preheader)}
                <h3>{this.props.title}</h3>
                {text(this.props.subhead)}
                <RatingsSummary starRatingURL={this.props.starRatingURL} />
            </div>
        );
    }

    private buildDescription() {
        if (!this.props.description) {
            return null;
        }
        return <RichText html={this.props.description} tagName="div" />;
    }

    componentDidUpdate() {
        if (this.configuratorElem) {
            this.setState({
                configuratorBottomPos:
                    getViewportBreakpoint() < 1201
                        ? window.innerHeight - 50
                        : this.configuratorElem.getBoundingClientRect().bottom,
            });
        }
    }

    render() {
        const variantIsAvailableToBuy = !!(
            this.props.upgradedVariant &&
            this.props.upgradedVariant.availability.is_available_to_buy
        );

        return (
            <article
                ref={(r) => {
                    this.configuratorElem = r;
                }}
                className={BEM_NAME}
            >
                {this.buildHeader()}
                {this.buildDescription()}
                {this.props.categorySelectors.map((selector) => (
                    <PLCProductCategorySelector
                        key={selector.id}
                        categorySelectors={this.props.categorySelectors}
                        optionPanels={this.props.optionPanels}
                        selector={selector}
                    />
                ))}
                {this.props.optionPanels.map((panel) => (
                    <PLCOptionPanel
                        key={panel.id}
                        categorySelectors={this.props.categorySelectors}
                        optionPanels={this.props.optionPanels}
                        panel={panel}
                    />
                ))}
                <ConfiguratorDeliveryDisclaimer
                    configuratorType={ConfiguratorTypes.PLC}
                    variantIsAvailableToBuy={variantIsAvailableToBuy}
                    rootProduct={this.props.rootProduct}
                    upgradedVariant={this.props.upgradedVariant}
                    showShippingLead={this.props.showShippingLead}
                    phoneNumber={this.props.phoneNumber}
                    getDeliveryCopy={this.props.getDeliveryCopy}
                    getDeliveryIcon={this.props.getDeliveryIcon}
                />
                {this.buildPrice()}
                <AddToBasketButton
                    basketLink={this.props.basketLink}
                    configureGiftsLink={this.props.configureGiftsLink}
                    configuratorType={ConfiguratorTypes.PLC}
                    buttonColor={"primary-congress-blue"}
                    showUpsellModal={this.props.showUpsellModal}
                    getUpsellModalComponentClass={
                        this.props.getUpsellModalComponentClass
                    }
                />
                {this.props.footer && (
                    <RichText
                        className={`${BEM_NAME}__footer`}
                        html={this.props.footer}
                    />
                )}
                <PLCProductMatchModal
                    categorySelectors={this.props.categorySelectors}
                    optionPanels={this.props.optionPanels}
                    modalPosition={this.state.configuratorBottomPos}
                />
            </article>
        );
    }
}
