import React from "react";
import { connect } from "react-redux";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { Dispatchers } from "../dispatchers";
import {
    IPLCBooleanOptionSelectorGroup,
    IPLCProductCategorySelector,
    IPLCOptionPanel,
} from "../models.interfaces";
import { PLCBooleanOptionSelector } from "./PLCBooleanOptionSelector";

// Load styles
import "./PLCBooleanOptionSelectorGroup.scss";

interface IOwnProps {
    categorySelectors: IPLCProductCategorySelector[];
    optionPanels: IPLCOptionPanel[];
    group: IPLCBooleanOptionSelectorGroup;
}

interface IReduxProps {}

interface IDispatchProps {
    setOptionValue: Dispatchers["setOptionValue"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

const BEM_NAME = "plc-boolean-option-selector-group";

class PLCBooleanOptionSelectorGroupComponent extends React.PureComponent<
    IProps,
    IState
> {
    render() {
        const groupID = this.props.group.id;
        return (
            <fieldset className={BEM_NAME}>
                <ul>
                    {this.props.group.value.selectors.map((selector) => (
                        <PLCBooleanOptionSelector
                            key={selector.name}
                            categorySelectors={this.props.categorySelectors}
                            optionPanels={this.props.optionPanels}
                            groupID={groupID}
                            selector={selector}
                        />
                    ))}
                </ul>
            </fieldset>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    _rootState,
    ownProps,
) => {
    return {
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        setOptionValue: (...args) => {
            dispatchers.setOptionValue(...args);
        },
    };
};

export const PLCBooleanOptionSelectorGroup = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PLCBooleanOptionSelectorGroupComponent);
