import React from "react";
import { t } from "ttag";
import Modal from "react-modal";
// import SVG from 'react-inlinesvg';
// import classNames from 'classnames';
import { FormInput } from "tsi-common-react/src/forms/FormInput";

interface IProps {
    isOpen: boolean;
    isSubmitting: boolean;
    onClose: () => void;
    onSubmit: (data: { recipient: string }) => void;
}

interface IState {
    recipient: string;
}

export class SurveyModalEmailResults extends React.Component<IProps, IState> {
    state: IState = {
        recipient: "",
    };

    private readonly onRecipientChange = (
        e: React.FormEvent<HTMLInputElement>,
    ) => {
        this.setState({
            recipient: e.currentTarget.value,
        });
    };

    private readonly onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.props.onSubmit({
            recipient: this.state.recipient,
        });
    };

    render() {
        return (
            <Modal
                aria={{
                    modal: true,
                }}
                style={{
                    content: {
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxHeight: "95%",
                        maxWidth: "542px",
                        width: "96%",
                        overflow: "auto",
                        padding: "40px",
                    },
                }}
                contentLabel="Survey"
                portalClassName="locator"
                className="locations store-locator-survey modal"
                isOpen={this.props.isOpen}
                onRequestClose={this.props.onClose}
                role="dialog"
            >
                <div className="modal-inner">
                    <button
                        aria-label={t`Close`}
                        className="modal-close pdp-modal__close"
                        onClick={this.props.onClose}
                    >
                        <div className="pdp-modal__close-icon modal-close__icon"></div>
                    </button>
                    <div className="send__content survey__carousel">
                        <h3>{t`Nearest retail locations`}</h3>
                        <p className="modal-intro">
                            {t`Send the nearest retailer locations straight to your email and access them from your phone.`}
                        </p>
                        <form onSubmit={this.onSubmit}>
                            <div className="form__field form__field--required form__field--email">
                                <FormInput
                                    required={true}
                                    placeholder={t`Enter your email address`}
                                    type="email"
                                    value={this.state.recipient}
                                    onChange={this.onRecipientChange}
                                    disabled={this.props.isSubmitting}
                                    id="email"
                                    name="email"
                                />
                            </div>
                            <div className="form__field form__field--submit">
                                <input
                                    type="submit"
                                    value={t`Send Results`}
                                    className="button"
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        );
    }
}
