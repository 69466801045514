import React from "react";
import { connect } from "react-redux";
import cookies from "js-cookie";
import { TStateMapper } from "tsi-common-react/src/apps/reducers.interfaces";
import { isoMiles } from "tsi-common-react/src/models/nominals";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { metersToMiles } from "tsi-common-react/src/utils/maps";
import { filteredStoreSelector } from "tsi-common-react/src/apps/retail/selectors";
import {
    ajax,
    CSRF_HEADER,
    getCSRFToken,
} from "tsi-common-react/src/utils/ajax";
import { SurveyModalEmailResults } from "../components/SurveyModal_EmailResults";
import { SurveyModalRateWebsite } from "../components/SurveyModal_RateWebsite";
import { SurveyModalThanks } from "../components/SurveyModal_Thanks";

enum ViewState {
    INITIAL,
    EMAIL_RESULTS,
    RATE_WEBSITE,
    THANKS,
    DONE,
}

enum CookieName {
    EMAIL_RESULTS = "locator_survey_email_taken",
    RATE_WEBSITE = "locator_survey_rating_taken",
    DISMISSED = "locator_survey_dismissed",
}

enum CookieValue {
    YES = "Y",
    NO = "N",
}

const SUBMIT_AUTH_KEY = "c78q87n0x44f";
const SUBMIT_BRAND = "SY";

interface IOwnProps {}

interface IReduxProps {
    stores: IRetailStoreWithDistance[];
}

interface IProps extends IOwnProps, IReduxProps {}

interface IState {
    view: ViewState;
    isSubmitting: boolean;
    emailAddr: string;
}

type EnumType = typeof CookieName;
type EnumKeyType = keyof EnumType;

class SurveyModalContainer extends React.Component<IProps, IState> {
    private readonly showEmailResultsModal = true;
    private initialStoresList = this.props.stores;
    private readonly surveyPrepopModePause = 50 * 1000; // seconds to pause in pre-pop mode

    state: IState = {
        view: ViewState.INITIAL,
        isSubmitting: false,
        emailAddr: "",
    };

    private readonly onClose = () => {
        cookies.set(CookieName.DISMISSED, CookieValue.YES);
        this.setState({
            view: ViewState.DONE,
        });
    };

    private readonly getStoresForEmail = () => {
        return this.props.stores.slice(0, 3).map((s) => {
            const destinationAddress = encodeURI(
                [s.address, s.address2, s.city, s.state, s.postal].join(" "),
            );
            return {
                address1: s.address,
                address2: s.address2,
                city: s.city,
                distance: `${isoMiles
                    .unwrap(metersToMiles(s.distance))
                    .toFixed(2)}`,
                name: s.name,
                phone: s.phone,
                state: s.state,
                url: `https://www.google.com/maps/dir/?daddr=${destinationAddress}`,
                zip: s.postal,
                lat: s.lat,
                lng: s.lng,
            };
        });
    };

    private readonly onSubmitEmail = async ({
        recipient,
    }: {
        recipient: string;
    }) => {
        this.setState({
            isSubmitting: true,
            emailAddr: recipient,
        });
        // select the first 3 stores
        // covert stores to email stores
        // Submit store data to email api

        const data = {
            email: recipient,
            stores: this.getStoresForEmail(),
        };
        try {
            await ajax
                .post("/api/email-retail-locator-results")
                .set("Accept", "application/json")
                .set(CSRF_HEADER, await getCSRFToken())
                .send(data);
        } catch (e) {
            console.error(e);
        } // another test
        this.setState({
            isSubmitting: false,
        });
        // Goto next state
        cookies.set(CookieName.EMAIL_RESULTS, CookieValue.YES);
        this.setState({
            view: ViewState.RATE_WEBSITE,
        });
    };

    private readonly onSubmitRating = async ({
        rating,
    }: {
        rating: number | null;
    }) => {
        this.setState({
            isSubmitting: true,
        });
        // Submit store data to Promosis
        const data = {
            email: this.state.emailAddr,
            auth: SUBMIT_AUTH_KEY,
            brand: SUBMIT_BRAND,
            survey_modal_result: rating,
        };
        try {
            await ajax
                .post("https://api.promosissweeps.com/rest/tsi-2190/survey.php")
                .type("form")
                .send(data);
        } catch (e) {
            console.log(e);
        }
        this.setState({
            isSubmitting: false,
        });
        // Goto next state
        cookies.set(CookieName.RATE_WEBSITE, CookieValue.YES);
        this.setState({
            view: ViewState.THANKS,
        });
        // Auto-close after 5 seconds
        setTimeout(() => {
            this.onClose();
        }, 5_000);
    };

    componentDidMount() {
        setTimeout(() => {
            this.initializeViewState();
        }, this.surveyPrepopModePause);
    }

    componentDidUpdate() {
        const hasNewResults = this.initialStoresList !== this.props.stores;
        if (hasNewResults && this.state.view === ViewState.DONE) {
            this.resetViewState();
            this.resetCookies();
        } else {
            this.initializeViewState();
        }
    }

    private initializeViewState() {
        if (this.state.view !== ViewState.INITIAL) {
            return;
        }
        if (this.props.stores.length <= 0) {
            return;
        }
        // Was closed?
        if (cookies.get(CookieName.DISMISSED) === CookieValue.YES) {
            this.setState({
                view: ViewState.DONE,
            });
            return;
        }
        // Need to email results?
        if (cookies.get(CookieName.EMAIL_RESULTS) !== CookieValue.YES) {
            this.setState({
                view: ViewState.EMAIL_RESULTS,
            });
            return;
        }
        // Need to rate website?
        if (cookies.get(CookieName.RATE_WEBSITE) !== CookieValue.YES) {
            this.setState({
                view: ViewState.RATE_WEBSITE,
            });
            return;
        }
        // Must be done
        this.setState({
            view: ViewState.DONE,
        });
    }

    private resetCookies() {
        Object.keys(CookieName).map((key) => {
            cookies.set(CookieName[key as EnumKeyType], CookieValue.NO);
        });
    }

    private resetViewState() {
        if (this.state.view !== ViewState.EMAIL_RESULTS) {
            this.initialStoresList = this.props.stores;
            this.setState({ view: ViewState.INITIAL });
        }
    }

    private readonly renderEmailResultsModal = () => {
        if (!this.showEmailResultsModal) {
            return null;
        }
        return (
            <SurveyModalEmailResults
                isOpen={this.state.view === ViewState.EMAIL_RESULTS}
                isSubmitting={this.state.isSubmitting}
                onClose={this.onClose}
                onSubmit={this.onSubmitEmail}
            />
        );
    };

    render() {
        return (
            <>
                {this.renderEmailResultsModal()}
                <SurveyModalRateWebsite
                    isOpen={this.state.view === ViewState.RATE_WEBSITE}
                    isSubmitting={this.state.isSubmitting}
                    onClose={this.onClose}
                    onSubmit={this.onSubmitRating}
                />
                <SurveyModalThanks
                    isOpen={this.state.view === ViewState.THANKS}
                    onClose={this.onClose}
                />
            </>
        );
    }
}

const mapStateToProps: TStateMapper<"retail", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        stores: filteredStoreSelector(rootState),
        ...ownProps,
    };
};

export const SurveyModal = connect(mapStateToProps)(SurveyModalContainer);
