import React from "react";
import { t } from "ttag";
import { connect } from "react-redux";
import className from "classnames";
import {
    IOptionSingleValue,
    IOptionValues,
    IProduct,
} from "../../../models/catalogue.interfaces";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import { getOptionValueByIdx } from "../utils";
import { Dispatchers } from "../dispatchers";
import { selectedCategoriesSelector } from "../selectors";
import {
    IPLCMattressFeelOptionSelector,
    IPLCProductCategorySelector,
    IPLCOptionPanel,
} from "../models.interfaces";
import { ISelectedCategories } from "../reducers.interfaces";
import { getMostSimilarProduct } from "../product-similarity";
import { PLCInfoPanel } from "./PLCInfoPanel";

// Load styles
import "./PLCMattressFeelOptionSelector.scss";

interface IOwnProps {
    categorySelectors: IPLCProductCategorySelector[];
    optionPanels: IPLCOptionPanel[];
    selector: IPLCMattressFeelOptionSelector;
}

interface IReduxProps {
    rootProducts: IProduct[];
    selectedCategories: ISelectedCategories;
    optionValues: IOptionValues;
    value: IOptionSingleValue | undefined;
}

interface IDispatchProps {
    onChange: Dispatchers["setOptionValue"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

const BEM_NAME = "plc-mattress-feel-option-selector";

const FEELS = [
    {
        label: t`Ultra Soft`,
    },
    {
        label: t`Soft`,
    },
    {
        label: t`Medium`,
    },
    {
        label: t`Firm`,
    },
    {
        label: t`Ultra Firm`,
    },
];

const FEEL_ATTR = "option_feel";

class PLCMattressFeelOptionSelectorComponent extends React.PureComponent<
    IProps,
    IState
> {
    /**
     * Check if selecting this option will result in a perfect product match or not. If
     * not, the option should be visually disabled (but still actually selectable).
     */
    private isDisabled(option: IOptionSingleValue): boolean {
        const futureOptionValues = {
            ...this.props.optionValues,
            [FEEL_ATTR]: option,
        };
        const result = getMostSimilarProduct({
            categorySelectors: this.props.categorySelectors,
            optionPanels: this.props.optionPanels,
            rootProducts: this.props.rootProducts,
            selectedCategories: this.props.selectedCategories,
            optionValues: futureOptionValues,
            previouslySelectedCategories: this.props.selectedCategories,
            previouslySelectedOptionValues: this.props.optionValues,
        });
        return !result || result.distance > 0;
    }

    render() {
        const groupID = this.props.selector.id;

        return (
            <fieldset className={BEM_NAME}>
                <legend>
                    <span className="ada-screenreader-only">
                        {t`Mattress Feel`}
                    </span>
                </legend>
                <ul>
                    {FEELS.map((opt) => {
                        const optionID = `${groupID}-${opt.label}`
                            .replace(/[^a-zA-Z0-9-]/gi, "")
                            .toLowerCase();
                        const isDisabled = this.isDisabled(opt.label);
                        const isChecked = this.props.value === opt.label;
                        const inputBorderClasses = className({
                            [`${BEM_NAME}__input-border`]: true,
                            [`${BEM_NAME}__input-border--checked`]: isChecked,
                        });
                        const inputClass = className({
                            [`${BEM_NAME}__input`]: true,
                            [`${BEM_NAME}__input--faux-disabled`]: isDisabled,
                        });
                        const labelClass = className({
                            [`${BEM_NAME}__label`]: true,
                            [`${BEM_NAME}__label--faux-disabled`]: isDisabled,
                        });
                        const textClass = className({
                            [`${BEM_NAME}__text`]: true,
                            [`${BEM_NAME}__text--checked`]: isChecked,
                        });
                        return (
                            <li
                                key={opt.label}
                                className={`${BEM_NAME}__option`}
                            >
                                <label
                                    htmlFor={optionID}
                                    className={labelClass}
                                    aria-selected={false}
                                >
                                    <span className={inputBorderClasses}>
                                        <input
                                            type="radio"
                                            className={inputClass}
                                            id={optionID}
                                            name={groupID}
                                            onChange={(e) => {
                                                if (e.currentTarget.checked) {
                                                    this.props.onChange(
                                                        "",
                                                        FEEL_ATTR,
                                                        0,
                                                        1,
                                                        opt.label,
                                                    );
                                                }
                                            }}
                                            checked={
                                                this.props.value === opt.label
                                            }
                                            value={opt.label}
                                        />
                                    </span>
                                    <span className={textClass}>
                                        {opt.label}
                                    </span>
                                </label>
                            </li>
                        );
                    })}
                </ul>
                <PLCInfoPanel
                    buttonText={t`Which feel is right for me?`}
                    content={this.props.selector.value.help_content}
                />
            </fieldset>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    return {
        rootProducts: state.entities.rootProducts,
        selectedCategories: selectedCategoriesSelector(state),
        optionValues: state.ui.optionValues,
        value: getOptionValueByIdx(state.ui.optionValues[FEEL_ATTR], 0),
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        onChange: (...args) => {
            dispatchers.setOptionValue(...args);
        },
    };
};

export const PLCMattressFeelOptionSelector = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PLCMattressFeelOptionSelectorComponent);
