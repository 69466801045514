import { onReadyStateComplete } from "tsi-common-react/src/utils/events";
import { IProduct } from "tsi-common-react/src/models/catalogue.interfaces";
import { store } from "../store";

const dynamicReviewValueProp = async () => {
    const state = store.getState();
    let reviewsNum = 0;
    state.configurator.entities.rootProducts.map((product: IProduct) => {
        reviewsNum += product.num_reviews;
    });

    const r = /REVIEW_COUNT_PLACEHOLDER/;
    const valueProps = document.getElementsByClassName("value-prop");
    Array.from(valueProps).map((elem) => {
        const children = elem.getElementsByTagName("*");

        Array.from(children).map((child) => {
            if (r.test(child.innerHTML)) {
                child.innerHTML = child.innerHTML.replace(
                    r,
                    `<br/>${reviewsNum}`,
                );
            }
        });
    });
};

// Register functions after body loads
onReadyStateComplete.on(() => {
    dynamicReviewValueProp();
});
